exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".JoinNewsletterModal__loaderScreen{position:fixed;left:50%;top:50vh;transform:translate(-50%,-50%);z-index:9999}.JoinNewsletterModal__root{position:relative}.JoinNewsletterModal__root .JoinNewsletterModal__form{margin-bottom:1em}.JoinNewsletterModal__root .JoinNewsletterModal__form input[type=email]{height:42px;border:1px solid #eaebeb;background:transparent!important;padding:10px;border-radius:5px;outline:none;width:100%}.JoinNewsletterModal__root .JoinNewsletterModal__form input[type=email]:focus{border-color:#2380db;box-shadow:0 0 0 2px rgba(25,220,213,.3)}.JoinNewsletterModal__root .JoinNewsletterModal__buttonFooter{text-align:right;width:100%}.JoinNewsletterModal__root .JoinNewsletterModal__error{font-style:italic;color:red;margin:.5em 0}.JoinNewsletterModal__root h2{margin-top:2em;width:100%;text-align:center}.JoinNewsletterModal__root p{text-align:center}", "", {"version":3,"sources":["/tmp/build_fcb8a7ba/web/js/components/site/widgets/JoinNewsletterModal.scss"],"names":[],"mappings":"AAAA,mCAAc,eAAe,SAAS,SAAS,+BAAgC,YAAY,CAAC,2BAAM,iBAAiB,CAAC,sDAAY,iBAAiB,CAAC,wEAA8B,YAAY,yBAAyB,iCAAkC,aAAa,kBAAkB,aAAa,UAAU,CAAC,8EAAoC,qBAAqB,wCAAyC,CAAC,8DAAoB,iBAAiB,UAAU,CAAC,uDAAa,kBAAkB,UAAU,aAAc,CAAC,8BAAS,eAAe,WAAW,iBAAiB,CAAC,6BAAQ,iBAAiB,CAAC","file":"JoinNewsletterModal.scss","sourcesContent":[".loaderScreen{position:fixed;left:50%;top:50vh;transform:translate(-50%, -50%);z-index:9999}.root{position:relative}.root .form{margin-bottom:1em}.root .form input[type=email]{height:42px;border:1px solid #EAEBEB;background:transparent !important;padding:10px;border-radius:5px;outline:none;width:100%}.root .form input[type=email]:focus{border-color:#2380db;box-shadow:0 0 0 2px rgba(25,220,213,0.3)}.root .buttonFooter{text-align:right;width:100%}.root .error{font-style:italic;color:red;margin:0.5em 0}.root h2{margin-top:2em;width:100%;text-align:center}.root p{text-align:center}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"loaderScreen": "JoinNewsletterModal__loaderScreen",
	"root": "JoinNewsletterModal__root",
	"form": "JoinNewsletterModal__form",
	"buttonFooter": "JoinNewsletterModal__buttonFooter",
	"error": "JoinNewsletterModal__error"
};